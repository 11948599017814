import React from 'react';
import { Button } from '@material-ui/core';

////////// COMPONENT //////////
export default function ModalAction(props) {
  const { className, onClick, color, size, variant, disabled } = props;
  return (
    <>
      <Button
        className={className}
        onClick={onClick}
        color={color || `primary`}
        size={size || `large`}
        variant={variant || `text`}
        disabled={disabled}
      >
        {props.children}
      </Button>
    </>
  );
}
